<template>
  <div>
    <div class="module">
      <div class="module-title">
        <h2>{{ $t('views.users.qrcodes.title') }}</h2>
      </div>

      <b-row>
            <b-col cols="auto">
              <div>
               <b-img :src="imageurl" fluid alt="Responsive image" />
              </div>
            </b-col>
          </b-row>
           <b-row>
            <b-col class="downloadbutton" cols="auto">
              <div>
               <b-button variant="primary" @click="downloadImage">{{ $t('views.users.qrcodes.download') }} <b-icon icon="arrow-down"></b-icon></b-button>
              </div>
            </b-col>
          </b-row>

  </div>
  </div>
</template>

<script>
import MixinEntityBase from '@/components/mixins/EntityBase'

export default {
  name: 'Users.Qrcodes',
  mixins: [MixinEntityBase],
  computed: {
    imageurl () {
      return `${this.$http.defaults.baseURL}/customer/api/${this.eParent.key}/${this.eParent.id}/qrcode`
    }
  },
  methods: {
    downloadImage () {
      const imageSrc = this.imageurl
      const link = document.createElement('a')
      link.href = imageSrc
      link.download = 'image.jpg'
      link.click()
    }
  }
}
</script>

<style lang="scss">
.downloadbutton{
  padding-top: 15px;
}
</style>
