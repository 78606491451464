<template>
  <formular :isBusy="is.loading" :controlDefinition="controlDefinition" maxWidth @formular:submit="onSubmit">
    <template #submit><b-icon icon="send" /> {{ $t("components.entity.notes.noteform.submit") }}</template>
  </formular>
</template>

<script>
import { CONTROL_COMPONENT_TO_DISCRIMINATOR_MAP } from "@/constants";

import Formular from "@/components/form/Formular";

export default {
  name: "NoteForm",
  components: {
    Formular,
  },
  props: {
    entityKey: {
      type: String,
      required: true,
    },
    entityId: {
      type: String,
      required: true,
    },
  },
  computed: {
    is() {
      return this.$store.getters[`${this.entityKey}/is`];
    },
    controlDefinition() {
      return {
        properties: [
          {
            attributeTypeDiscriminator: CONTROL_COMPONENT_TO_DISCRIMINATOR_MAP.textarea.type,
            name: "text",
            settings: {
              labelCols: 4,
              validators: ["requiredValidator"],
            },
            translations: {
              [this.$i18n.locale]: {
                name: this.$t(`components.entity.notes.${this.$options.name.toLowerCase()}.controls.text.label`),
              },
            },
          },
        ],
      };
    },
  },
  methods: {
    onSubmit(formData) {
      this.$store.dispatch(`${this.entityKey}/createNote`, {
        entityId: this.entityId,
        note: formData.controls,
      });
    },
  },
};
</script>

<style lang="scss"></style>
